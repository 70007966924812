@font-face {
  font-family: 'Montserrat';
  src: url('https://cdn.carlsberggroup.com/fonts/montserrat/Montserrat-VF.woff2')
      format('woff2'),
    url('https://cdn.carlsberggroup.com/fonts/montserrat/Montserrat-VariableFont_wght.ttf')
      format('truetype');
  font-display: 'swap';
  font-weight: 400 500 700 800 900;
  font-stretch: 100%;
}

@font-face {
  font-family: 'CarlsbergSans';
  font-weight: 300;
  src: url('../assets/fonts/CarlsbergSansLight/CarlsbergSansLight.woff2')
      format('woff2'),
    url('../assets/fonts/CarlsbergSansLight/CarlsbergSansLight.woff')
      format('woff'),
    url('../assets/fonts/CarlsbergSansLight/CarlsbergSansLight.ttf')
      format('truetype');
}

@font-face {
  font-family: 'CarlsbergSans';
  font-weight: 900;
  src: url('../assets/fonts/CarlsbergSansBlack/CarlsbergSansBlack.woff2')
      format('woff2'),
    url('../assets/fonts/CarlsbergSansBlack/CarlsbergSansBlack.woff')
      format('woff'),
    url('../assets/fonts/CarlsbergSansBlack/CarlsbergSansBlack.ttf')
      format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-weight: 400;
  src: url('../assets/fonts/ubuntu/Ubuntu-Regular.woff2') format('woff2'),
    url('../assets/fonts/ubuntu/Ubuntu-Regular.woff') format('woff'),
    url('../assets/fonts/ubuntu/Ubuntu-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Ubuntu';
  font-weight: 700;
  src: url('../assets/fonts/ubuntu/Ubuntu-Bold.woff2') format('woff2'),
    url('../assets/fonts/ubuntu/Ubuntu-Bold.woff') format('woff'),
    url('../assets/fonts/ubuntu/Ubuntu-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Alkatip';
  font-weight: 900;
  src: url('../assets/fonts/alkatip/AlkatipBasmaTomBold.woff2') format('woff2'),
    url('../assets/fonts/alkatip/AlkatipBasmaTomBold.woff') format('woff'),
    url('../assets/fonts/alkatip/AlkatipBasmaTomBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Alkatip';
  font-weight: 700;
  src: url('../assets/fonts/alkatip/AlkatipBasmaTomBold.woff2') format('woff2'),
    url('../assets/fonts/alkatip/AlkatipBasmaTomBold.woff') format('woff'),
    url('../assets/fonts/alkatip/AlkatipBasmaTomBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Alkatip';
  font-weight: 400;
  src: url('../assets/fonts/alkatip/AlkatipBasmaTom.woff2') format('woff2'),
    url('../assets/fonts/alkatip/AlkatipBasmaTom.woff') format('woff'),
    url('../assets/fonts/alkatip/AlkatipBasmaTom.ttf') format('truetype');
}
