@font-face {
  font-family: 'icomoon';
  src: url('../assets/fonts/icomoon/icomoon.eot?7u8cug');
  src: url('../assets/fonts/icomoon/icomoon.eot?7u8cug#iefix')
      format('embedded-opentype'),
    url('../assets/fonts/icomoon/icomoon.ttf?7u8cug') format('truetype'),
    url('../assets/fonts/icomoon/icomoon.woff?7u8cug') format('woff'),
    url('../assets/fonts/icomoon/icomoon.svg?7u8cug#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-survey-trophy:before {
  content: '\e94d';
}
.icon-calendar1:before {
  content: '\e94e';
}
.icon-help1:before {
  content: '\e948';
}
.icon-trophy1:before {
  content: '\e949';
}
.icon-survey-21:before {
  content: '\e94a';
}
.icon-pin-active:before {
  content: '\e94b';
}
.icon-target:before {
  content: '\e94c';
}
.icon-rd-party1:before {
  content: '\e909';
}
.icon-alert-connection:before {
  content: '\e90a';
}
.icon-camera:before {
  content: '\e90b';
}
.icon-thumb-up:before {
  content: '\e90c';
}
.icon-app-dropbox:before {
  content: '\e944';
}
.icon-file:before {
  content: '\e945';
}
.icon-shield-security:before {
  content: '\e946';
}
.icon-speach-balloon-livechat:before {
  content: '\e947';
}
.icon-cookie:before {
  content: '\e908';
}
.icon-Accessibility:before {
  content: '\e91b';
}
.icon-Cancel:before {
  content: '\e937';
}
.icon-Feedback:before {
  content: '\e938';
}
.icon-Global-globe:before {
  content: '\e939';
}
.icon-Library:before {
  content: '\e93a';
}
.icon-link:before {
  content: '\e93b';
}
.icon-my-projects:before {
  content: '\e93c';
}
.icon-Prohibited:before {
  content: '\e93d';
}
.icon-Sync:before {
  content: '\e93e';
}
.icon-trophy:before {
  content: '\e93f';
}
.icon-Upload:before {
  content: '\e940';
}
.icon-Wifi:before {
  content: '\e941';
}
.icon-diploma:before {
  content: '\e942';
}
.icon-liability:before {
  content: '\e943';
}
.icon-salesrep-2:before {
  content: '\e900';
}
.icon-dot:before {
  content: '\e934';
}
.icon-dropdown:before {
  content: '\e935';
}
.icon-icons-small:before {
  content: '\e936';
}
.icon-rd-party:before {
  content: '\e911';
}
.icon-justice:before {
  content: '\e927';
}
.icon-arrow-know-more:before {
  content: '\e92f';
}
.icon-arrow-read-more:before {
  content: '\e930';
}
.icon-diagonal-up-left:before {
  content: '\e931';
}
.icon-diagonal-up-right1:before {
  content: '\e932';
}
.icon-mug:before {
  content: '\e933';
}
.icon-balance:before {
  content: '\e901';
}
.icon-camera-2:before {
  content: '\e902';
}
.icon-carlsber-symbol-fill:before {
  content: '\e903';
}
.icon-carlsber-symbol:before {
  content: '\e904';
}
.icon-change:before {
  content: '\e905';
}
.icon-complaint:before {
  content: '\e906';
}
.icon-contract-and-pen:before {
  content: '\e907';
}
.icon-customer:before {
  content: '\e90d';
}
.icon-data-transfer:before {
  content: '\e90e';
}
.icon-diagonal-up-right:before {
  content: '\e90f';
}
.icon-diploma-2:before {
  content: '\e910';
}
.icon-edit:before {
  content: '\e912';
}
.icon-email:before {
  content: '\e913';
}
.icon-euro-coin:before {
  content: '\e914';
}
.icon-flash-full:before {
  content: '\e915';
}
.icon-global-2:before {
  content: '\e916';
}
.icon-hand:before {
  content: '\e917';
}
.icon-heart:before {
  content: '\e918';
}
.icon-help:before {
  content: '\e919';
}
.icon-info:before {
  content: '\e91a';
}
.icon-like:before {
  content: '\e91c';
}
.icon-location-ip:before {
  content: '\e91d';
}
.icon-lock:before {
  content: '\e91e';
}
.icon-mouse:before {
  content: '\e91f';
}
.icon-new-customers:before {
  content: '\e920';
}
.icon-participant:before {
  content: '\e921';
}
.icon-people:before {
  content: '\e922';
}
.icon-phone-mobile:before {
  content: '\e923';
}
.icon-pin-labeling:before {
  content: '\e924';
}
.icon-power:before {
  content: '\e925';
}
.icon-question:before {
  content: '\e926';
}
.icon-settings-double:before {
  content: '\e928';
}
.icon-share:before {
  content: '\e929';
}
.icon-sign-out:before {
  content: '\e92a';
}
.icon-survey-2:before {
  content: '\e92b';
}
.icon-sync:before {
  content: '\e92c';
}
.icon-technical-support:before {
  content: '\e92d';
}
.icon-update:before {
  content: '\e92e';
}
