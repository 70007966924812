@import './custom-font';
@import './reset';
@import './icomoon';
@import './grid';
@import './variables';

body,
html {
  background: white;
  font-family: var(--font-primary);
  text-rendering: optimizeLegibility;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
}

body {
  overflow-x: hidden;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px $inputs-color inset;
}

ul {
  padding-inline-start: 0;
}

a {
  color: $light-text;
  font-size: $font-size-body1;

  @media (max-width: $B2) {
    font-size: $font-size-body1-mobile;
  }

  &::selection {
    background: $selected-text;
  }

  &:visited,
  &:active {
    color: $light-text;
  }

  &:hover {
    color: $light-text;
  }
}

.uppercase {
  text-transform: uppercase;
}

span::selection {
  background: $selected-text;
}

span.break {
  display: block;
  height: 14px;
}

article a,
p a {
  color: $primary-color;
  text-decoration: underline;

  &:hover,
  &:visited,
  &.active {
    color: $primary-color;
  }
}

article {
  span.list {
    display: block;
    padding-left: 30px;
    margin-top: 15px;

    span.list-item {
      display: block;
      position: relative;
      margin-top: 14px;

      &::before {
        content: '\e934';
        position: absolute;
        left: -25px;
        font-family: 'icomoon';
        top: 4px;
        font-size: 10px;
      }
    }
  }
}
